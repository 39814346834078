@import "__var.scss";

.Content{
    flex: 1;
    max-width:1460px;
    min-width:360px;
    display:flex;
    flex-direction:column;
    gap:20px;
    margin:0 auto;

    background-color: $col-wht1;
    color:$txt-blk1;

}