@import "__var.scss";

#head-nav{
    background-color: $col-blk1;
    & .MuiTypography-h4{
        color:$txt-wht1;
    }
    & .MuiButton-contained{
        color:$txt-blk1;
        background-color:$col-wht1;
        border-radius:0;
        border-width:2px;
    }

    & .MuiButton-outlined{
        color:$txt-wht1;
        border-radius:1px;
        border:3px solid $col-wht1;
    }

}

#int-nav-menu{
    & .MuiList-root{
        background-color: $col-blk1;
        color:$txt-wht1;
    }
}