@import "__var.scss";

.App {
  text-align: center;
  background: $col-wht1;
  min-height:100vh;
  display:flex;
  flex-direction:column;

}
