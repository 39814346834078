.ProjContainer{
    max-width:1120px;
    min-width:320px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content: space-around;
    align-items:flex-start;
    align-content:space-around;
    gap:40px 40px;
    margin:0 auto;

}
.Projects>.MuiTypography-root{
    margin:0 0 1rem 0;
}
#proj-app-bar{
    max-width:1120px;
    min-width:320px;
    margin:0 auto;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:flex-end;
    gap:10px 10px;
}

@media only screen and (max-width: 740px) {
    .Projects>#proj-app-bar{
        justify-content:space-around;
    }
} 
