@import '__var.scss';

.mui-logo, .d3-logo{
    height: 1.5rem;
    width:1.5rem;
    filter:invert(1)
}

.tech-logo{
    font-size:1.5rem;
    color:$txt-blk1;
}

.ProjectItem.MuiPaper-root {
    background-color: $col-wht1;
    
    & .MuiCardContent-root{
        margin:0.25rem;
        color:$txt-blk1;
        & .MuiTypography-h5{
            margin:0.25rem 0 0.25rem 0;
        }
    
    }
    & .MuiButton-outlined{
        color:$txt-blk1;
        border-radius:1px;
        border:3px solid $col-blk1;
    }

}